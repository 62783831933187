
.view-container{
    width:100%;
    height:100%;
}


@media screen and (orientation:landscape) {
    
    .view-portrait-img{
        display:none;
    }



    .view-mode-wrapper{
        display:flex;
        flex-direction:column;
        align-items:center;
        width: 80%;
        margin-left:auto;
        margin-right:auto; 
    }
}

@media screen and (orientation:portrait) {

    .view-portrait-img{
        display:flex;
        width:100%;
        height:100%;        
    }

    .view-portrait-img img{
        width:100%;
        height:100%;
    }

    .view-mode-wrapper{
        display:flex;
        flex-direction:column;
        align-items:center;
        width: 100%;
    }
}

    

.print-mode-wrapper{
    display:flex;
    align-items:left;
    width:100vw;
    height:100vh;
    background:white;
}

.view-error{
    margin-top:100px;
    width:100%;
    display:flex;
    justify-content:center;
    text-align:center;
}

.view-error-header{
    font-family:"TeleNeoRegular";
    color:white;
    font-size:3em;
    text-align:center;
}

.print-view-error-header{
    font-family:"TeleNeoRegular";
    color:black;
    font-size:3em;
    text-align:center; 
}

.view-error-subheader{
    font-family:"TeleNeo";
    color:white;
    margin-top:20px;
    font-size:1.6em;
    text-align:center;
}

.selfie-view-container{
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 100%;
    margin-left:auto;
    margin-right:auto;  
    transition:opacity 0.25s ease-out;
}
.hidden-view{
    opacity:0;
}

.view-header{
    width:100%;
    height:20%;
    margin-top:40px;
    margin-bottom:20px;
    display:flex;
    align-items:center;
    justify-content: center;
}
.view-header img{
    max-width:565px;
    width:80%;
}

.view-header-text-wrapper{
    color:white;
    width: 80%;

}

.view-image{   
    max-width:782px;
    margin-bottom:20px;
    border: 10px solid white;
    display:flex;
    align-items:center;
    justify-content:center;
}

.view-image-print img{
    position: absolute;
    top: 0;
    left: 0;
    width: 600px;
    height: 400px;
}

.view-image img{
    width:100%;
    height:auto;
    max-width:782px;
    max-height:521px;
}

.view-instructions{
    display:flex;
    width:100%;
    max-width:400px;
    align-items:center;
    justify-content:center;
    height: 20%;
}

.view-instructions img{
    max-width:150px;
    min-width:80px;
    width:100%;
}
.view-instructions-text{
    display:flex;
    align-items:center;
    justify-content:center;
}

.view-instructions-text p{
    color:white;
    font-family:TeleNeo;
    font-size:1.1em;
    text-align:center;

}


.view-legal{
    width:60%;
    display:flex;
    align-items:center;
    justify-content:center
}




.view-legal p{
    text-align: center;
    font: normal normal medium 12px/13px TeleNeo;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}