
@page {
  size: auto;   /* auto is the initial value */
  margin: 0;  /* this affects the margin in the printer settings */
}



.App {
  text-align: center;
  background-color:#E20074;
  display:flex;
  align-items:baseline;
  width:100vw;
  height:100vh;
  overflow-y:scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.App-header, div{
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.body {
  background-color:rgb(207, 45, 115);
}

.container{
  width:100%;
  height:100vh;
}


@font-face {
  font-family: 'TeleNeo';
  src: local('TeleNeo'), url(../assets/TeleNeoWeb/TeleNeoWeb-Thin.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'TeleNeoRegular';
  src: local('TeleNeoRegular'), url(../assets/TeleNeoWeb/TeleNeoWeb-Regular.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}